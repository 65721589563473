import React, {useEffect, useState} from 'react';
import Table from "../components/table";
import EditEntity from "./editEntity";
import {supplierStructure} from "../utils/structues";
import {constants} from "../utils/constants";

export default function Supplier() {
    const [suppliers, setSuppliers] = useState()
    const [editing, setEditing] = useState()
    const [editedRow, setEditedRow] = useState()

    useEffect(() => {
        loadResources()
    }, [])

    function loadResources() {
        fetch(`${constants.API_URL}/suppliers`)
            .then(response => response.json())
            .then(suppliers => setSuppliers(suppliers))
            .catch((e) => console.error(e))
    }

    function onEditClose() {
        setEditing(undefined)
        setEditedRow(undefined)
        loadResources()
    }

    if (editing) {
        return <EditEntity onClose={onEditClose} previousEntity={editedRow} structure={supplierStructure}/>
    }

    return <>
        <h1>Fournisseurs</h1>
        <div className={'actions'}>
            <button onClick={() => setEditing(true)}>+ Ajouter</button>
        </div>
        <Table headers={supplierStructure.headers} data={suppliers} edit={(row) => {
            setEditedRow(row)
            setEditing(true)
        }}/>
    </>
}
