import React, {useState} from 'react';
import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`
  .burgerMenu {
    position: fixed;
    z-index: 3;
    height: 40px;
    width: 40px;
    padding: 10px;
    border: none;
    
    > img {
      height: 20px;
    }

    @media (min-width: 900px) {
      display: none;
    }
  }
  
  .nav {
    display: none;
    flex-direction: column;
    min-width: 200px;
    height: 100%;
    gap: 30px;
    padding: 2rem 1rem;
    border-right: var(--primary) 1px solid;
    
    &.open {
      display: flex;
      position: fixed;
      z-index: 2;
      background-color: white;
    }
    
    @media (min-width: 900px) {
      display: flex;
    }

    button.logo {
      background: none;
      border: none;
      cursor: pointer;
    }

    button.logo img {
      height: 100px;
      width: 100px;
    }

    button:not(.logo) {
      background: none;
      border: none;
      cursor: pointer;
      color: var(--primary);
      padding: 1rem;
      border-radius: 8px;
    }

    button:not(.logo):hover {
      background-color: var(--primary-background);
    }
  }
`

export default function Navbar({active, onChange}) {
    const [navbarOpen, setNavbarOpen] = useState(false)

    return <>
        <button className={"burgerMenu"} onClick={() => setNavbarOpen(!navbarOpen)}>
            <img src={navbarOpen ? "close_icon.png" : "menu_icon.png"} alt="ouvrir le menu"/>
        </button>
        <nav className={`nav ${navbarOpen && 'open'}`}>
        <GlobalStyle />
        <button className={'logo'} onClick={() => {
            setNavbarOpen(false)
            onChange('landing')
        }}>
            <img src="logo.png" alt="Retourner à l'accueil"/>
        </button>
        <button className={`${active === 'order' && 'active'}`} onClick={() => {
            setNavbarOpen(false)
            onChange('order')
        }}>
            Commandes
        </button>
        <button className={`${active === 'product' && 'active'}`} onClick={() => {
            setNavbarOpen(false)
            onChange('product')
        }}>
            Produits
        </button>
        <button className={`${active === 'supplier' && 'active'}`} onClick={() => {
            setNavbarOpen(false)
            onChange('supplier')
        }}>
            Fournisseurs
        </button>
        <button className={`${active === 'customer' && 'active'}`} onClick={() => {
            setNavbarOpen(false)
            onChange('customer')
        }}>
            Clients
        </button>
        <button className={`${active === 'shipping' && 'active'}`} onClick={() => {
            setNavbarOpen(false)
            onChange('shipping')
        }}>
            Expéditions
        </button>
    </nav>
    </>
}