import React, {useEffect, useState} from 'react';
import Table from "../components/table";
import EditEntity from "./editEntity";
import {productStructure} from "../utils/structues";
import {constants} from "../utils/constants";

export default function Product({getRelatedShipping}) {
    const [products, setProducts] = useState()
    const [editing, setEditing] = useState()
    const [editedRow, setEditedRow] = useState()

    useEffect(() => {
        loadResources()
    }, [])

    function loadResources() {
        fetch(`${constants.API_URL}/products`)
            .then(response => response.json())
            .then(products => setProducts(products))
            .catch((e) => console.error(e))
    }

    function onEditClose() {
        setEditing(undefined)
        setEditedRow(undefined)
        loadResources()
    }

    if (editing) {
        return <EditEntity
            onClose={onEditClose}
            previousEntity={editedRow}
            structure={productStructure}
            getRelatedShipping={getRelatedShipping}/>
    }

    return <>
        <h1>Produits</h1>
        <div className={'actions'}>
            <button onClick={() => setEditing(true)}>+ Ajouter</button>
        </div>
        <Table headers={productStructure.headers} data={products} edit={(row) => {
            setEditedRow(row)
            setEditing(true)
        }}/>
    </>
}
