import React, {useEffect, useState} from 'react';
import Table from "../components/table";
import EditEntity from "./editEntity";
import {customerStructure} from "../utils/structues";
import {constants} from "../utils/constants";

export default function Customer() {
    const [customers, setCustomers] = useState()
    const [editing, setEditing] = useState()
    const [editedRow, setEditedRow] = useState()

    useEffect(() => {
        loadResources()
    }, [])

    function loadResources() {
        fetch(`${constants.API_URL}/customers`)
            .then(response => response.json())
            .then(customers => setCustomers(customers))
            .catch((e) => console.error(e))
    }

    function onEditClose() {
        setEditing(undefined)
        setEditedRow(undefined)
        loadResources()
    }

    if (editing) {
        return <EditEntity onClose={onEditClose} previousEntity={editedRow} structure={customerStructure}/>
    }

    return <>
        <h1>Clients</h1>
        <div className={'actions'}>
            <button onClick={() => setEditing(true)}>+ Ajouter</button>
        </div>
        <Table headers={customerStructure.headers} data={customers} edit={(row) => {
            setEditedRow(row)
            setEditing(true)
        }}/>
    </>
}
