import React from 'react';
import {createGlobalStyle} from "styled-components";
import EditEntity from "../pages/editEntity";
import {productStructure, supplierStructure} from "../utils/structues";
import {constants} from "../utils/constants";

const GlobalStyle = createGlobalStyle`
  .entityModal {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    
    &.visible {
      display: flex;
    }
    
    > div {
      height: 80vh;
      width: 80vw;
      max-width: 900px;
      background-color: white;
      border-radius: 20px;
      overflow-y: scroll;
      padding: 20px;
    }
  }
`

export default function EntityModal({field, display, onChange, onClose}) {
    const structure = field.content.entry === "supplier" ? supplierStructure
        : productStructure

    function submitEntity(entity) {
        fetch(`${constants.API_URL}/${structure.type.entry}`, {
            method: "POST",
            body: JSON.stringify(entity),
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            onChange(res)
            onClose()
        }).catch(e => console.error(e))
    }

    return <div className={`entityModal ${display && 'visible'}`} onClick={(e) => e?.target?.className === "entityModal visible" && onClose()}>
        <GlobalStyle/>
        <div>
            <EditEntity structure={structure} onClose={(e) => {
                e.preventDefault()
                onClose()
            }} onCreate={(e, value) => {
                e.preventDefault()
                submitEntity(value)
            }}/>
        </div>
    </div>
}
