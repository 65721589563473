import React from 'react';
import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`
  .table {
    border-collapse: collapse;
    font-family: sans-serif;
    text-align: left;
    
    thead {
      background-color: var(--primary-background);
    }

    th,
    td {
      border-bottom: 1px solid var(--primary-background);
      padding: 1em;
      min-width: 150px;
    }
    
    &.min th,
    &.min td {
      padding: .5em;
      min-width: unset;
      font-size: .7em;
    }
    
    &__action {
      padding: .2em;
      border: none;
    }
  }
`

export default function Table({headers, data, edit}) {
    if (!headers || !data) {
        return
    }

    return <table className={"table min"}>
        <GlobalStyle />
        <thead>
            <tr>
                {(Object.values(headers) || []).map(header => <th scope="col" key={header}>
                    {header}
                </th>)}
                <th key={"actions"}>
                    Actions
                </th>
            </tr>
        </thead>
        <tbody>
            {(data || []).map(row => <tr key={data._id}>
                {(Object.keys(headers) || []).map(key => {
                    const keys = key.split(".")
                    let value = keys.length <= 1 ? row[key] : row[keys[0]][keys[1]]
                    if (value === true || value === false) {
                        value = value === true ? "Oui" : "Non"
                    }
                    if (typeof value === "string") {
                        value = value.replace('T00:00:00.000Z', '')
                    }
                    return <td key={key}>
                        {value}
                    </td>
                })}
                <td>
                    <button className={"table__action"} onClick={() => edit(row)}>Voir / Modifier</button>
                </td>
            </tr>
            )}
        </tbody>
    </table>
}