exports.orderStructure = {
    type: {
        name: "Commandes",
        entry: "orders"
    },
    headers: {
        invoice: "Facture",
        receptionDate: "Date de réception",
        "supplier.name": "Fournisseur"
    },
    fields: [
        {
            name: "Facture",
            entry: "invoice",
            type: "text",
            isRequired: true
        }, {
            name: "Date de réception",
            entry: "receptionDate",
            type: "date",
            isRequired: false,
            // default: new Date().toISOString().split("T")[0]
        }, {
            name: "Fournisseur",
            entry: "supplier",
            type: "entity",
            isRequired: true
        }, {
            name: "Panier",
            entry: "agreement",
            type: "list",
            isRequired: true,
            content: {
                name: "Produit",
                entry: "product",
                type: "entity",
                isRequired: true
            }
        }
    ]
}

exports.customerStructure = {
    type: {
        name: "Clients",
        entry: "customers"
    },
    headers: {
        name: "Nom"
    },
    fields: [
        {
            name: "Nom",
            entry: "name",
            type: "text",
            isRequired: true
        }
    ]
}

exports.productStructure = {
    type: {
        name: "Produits",
        entry: "products"
    },
    headers: {
        name: "Nom",
        batchRef: "N° de lot",
        quantity: "Quantité",
        isActive: "En stock"
    },
    fields: [
        {
            name: "Nom",
            entry: "name",
            type: "text",
            isRequired: true
        }, {
            name: "Fournisseur",
            entry: "supplier",
            type: "entity",
            isRequired: false
        }, {
            name: "N° de lot",
            entry: "batchRef",
            type: "text",
            isRequired: true
        }, {
            name: "En stock",
            entry: "isActive",
            type: "checkbox",
            isRequired: false,
            default: true
        },
        // {
        //     name: "Photo",
        //     entry: "picture",
        //     type: "file",
        //     isRequired: false
        // },
        {
            name: "Origine",
            entry: "origin",
            type: "text",
            isRequired: false
        }, {
            name: "Bio",
            entry: "isOrganic",
            type: "checkbox",
            isRequired: false
        }, {
            name: "Date de Durabilité Minimale",
            entry: "minDurabilityDate",
            type: "month",
            isRequired: false
        }, {
            name: "Quantité (en kg)",
            entry: "quantity",
            type: "number",
            isRequired: true
        }
    ]
}

exports.shippingStructure = {
    type: {
        name: "Expéditions",
        entry: "shippings"
    },
    headers: {
        invoice: "Facture",
        date: "Date d'expédition",
        "customer.name": "Client",
        "product.name": "Produit",
        "quantity": "Quantité",
    },
    fields: [
        {
            name: "Facture",
            entry: "invoice",
            type: "text",
            isRequired: true
        }, {
            name: "Date d'expédition",
            entry: "date",
            type: "date",
            isRequired: true,
            // default: new Date().toISOString().split("T")[0]
        }, {
            name: "Client",
            entry: "customer",
            type: "entity",
            isRequired: true
        }, {
            name: "Produit",
            entry: "product",
            type: "entity",
            isRequired: true
        }, {
            name: "Quantité (en kg)",
            entry: "quantity",
            type: "number",
            isRequired: true
        }
    ]
}

exports.supplierStructure = {
    type: {
        name: "Fournisseurs",
        entry: "suppliers"
    },
    headers: {
        "name": "Nom",
        "code": "Code"
    },
    fields: [
        {
            name: "Nom",
            entry: "name",
            type: "text",
            isRequired: true
        },
        {
            name: "Code",
            entry: "code",
            type: "text",
            isRequired: false
        },
    ]
}