import React, {useEffect, useState} from 'react';
import Table from "../components/table";
import {shippingStructure} from "../utils/structues";
import EditEntity from "./editEntity";
import {constants} from "../utils/constants";

export default function Shipping({searchProduct, onSearched}) {
    const [shippings, setShippings] = useState()
    const [editing, setEditing] = useState()
    const [editedRow, setEditedRow] = useState()

    useEffect(() => {
        loadResources()
    }, [])

    function loadResources() {
        fetch(`${constants.API_URL}/shippings?product=${searchProduct}`)
            .then(response => response.json())
            .then(shippings => {
                setShippings(shippings)
                onSearched()
            })
            .catch((e) => console.error(e))
    }

    function onEditClose() {
        setEditing(undefined)
        setEditedRow(undefined)
        loadResources()
    }

    if (editing) {
        return <EditEntity onClose={onEditClose} previousEntity={editedRow} structure={shippingStructure}/>
    }

    return <>
        <h1>Expéditions</h1>
        <div className={'actions'}>
            <button onClick={() => setEditing(true)}>+ Ajouter</button>
        </div>
        <Table headers={shippingStructure.headers} data={shippings} edit={(row) => {
            setEditedRow(row)
            setEditing(true)
        }}/>
    </>
}
