import Navbar from "./components/navbar";
import {useState} from "react";
import Order from "./pages/order";
import Customer from "./pages/customer";
import Product from "./pages/product";
import Shipping from "./pages/shipping";
import Supplier from "./pages/supplier";
import Landing from "./pages/landing";
import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`
  .app {
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  .tableManager {
    width: 100%;
    padding: 2rem;
    overflow-x: auto;
    
    .actions {
      display: flex;
      min-height: 50px;
      justify-content: flex-end;
      margin-bottom: 1em;
    }
  }
`

function App() {
    const [page, setPage] = useState('landing')
    const [user, setUser] = useState()
    const [searchedProduct, setSearchedProduct] = useState()

  return (
    <div className="app">
        <GlobalStyle/>
        {user && <Navbar active={page} onChange={(value) => setPage(value)}/>}
        <div className={"tableManager"}>
            {page === "landing" && <Landing user={user} onAuth={(connected) => setUser(connected)} />}
            {page === "order" && <Order />}
            {page === "product" && <Product getRelatedShipping={(productId) => {
                setSearchedProduct(productId)
                setPage("shipping")
            }}/>}
            {page === "supplier" && <Supplier />}
            {page === "customer" && <Customer />}
            {page === "shipping" && <Shipping searchProduct={searchedProduct} onSearched={() => setSearchedProduct(undefined)}/>}
        </div>
    </div>
  );
}

export default App;
