import React, {useEffect, useState} from 'react';
import {createGlobalStyle} from "styled-components";
import EntityModal from "../components/modal";
import {productStructure, supplierStructure} from "../utils/structues";
import {constants} from "../utils/constants";

const GlobalStyle = createGlobalStyle`
  .editEntityForm {
    display: flex;
    flex-direction: column;
    gap: 1em;
    
    > div {
      display: flex;
      flex-direction: column;
      
      > label {
        margin-bottom: .5rem;
      }
      
      input,
      select {
        padding: .8rem 1rem;
        border: 1px solid var(--grey);
        border-radius: .5rem;
        max-width: 30rem;
        box-sizing: border-box;
      }
    }
    
    .listItem {
      display: flex;
      border-top: 1px solid var(--primary);
      
      > span {
        padding: 10px;
      }
    }

    .saveButton {
      color: var(--success);
      border-color: var(--success);
      max-width: 300px;
      margin-top: 2rem;
      
      &:hover {
        color: var(--white);
        background-color: var(--success);
      }
    }
  }
  
  .actions {
    display: flex;
    gap: 1em;
  }
  
  h1 {
    word-break: break-word;
  }
`

export default function EditEntity({previousEntity, structure, onClose, onCreate, getRelatedShipping}) {
    const [entity, setEntity] = useState(previousEntity || {})

    if (!entity) {
        return
    }

    function submitEntity(entity) {
        fetch(`${constants.API_URL}/${previousEntity ? `${structure.type.entry}/${previousEntity._id}` : structure.type.entry}`, {
            method: previousEntity ? "PUT" : "POST",
            body: JSON.stringify(entity),
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            onClose()
        }).catch(e => console.error(e))
    }

    return <>
        <GlobalStyle/>
        <h1>{previousEntity ? `Edition de ${structure.type.name} n° ${previousEntity._id}` : `Création de ${structure.type.name}`}</h1>
        <div className={'actions'}>
            {(previousEntity && structure.type.entry === "products") && <button onClick={() => getRelatedShipping(previousEntity._id)}>Retrouver les expéditions</button>}
            <button onClick={onClose}>Quitter</button>
        </div>
        <form className={'editEntityForm'} onSubmit={(e) => {
            e.preventDefault()
            submitEntity(entity)
        }}>
            {structure.fields.map(field => <div key={field.name}>
                <label htmlFor={field.name}>{field.name}</label>
                {field.type === "list" ? <ListField
                    field={field}
                    value={entity[field.entry]}
                    onChange={(values) => {
                        const updatedEntity = entity
                        updatedEntity[field.entry] = values
                        setEntity(updatedEntity)
                    }}
                    /> : field.type === "entity" ? <EntityField
                    field={field}
                    value={entity[field.entry]}
                    onChange={(e) => {
                        const updatedEntity = entity
                        updatedEntity[field.entry] = e.target.value
                        setEntity(updatedEntity)
                }}/> : <input
                    style={field.type === "checkbox" ? {width: "max-content"} : {}}
                    name={field.name}
                    type={field.type}
                    checked={entity[field.entry]}
                    value={!previousEntity ? field.default
                        :field.type === "date" ? (entity[field.entry] || '').slice(0, 10)
                        : field.type === "month" ? (entity[field.entry] || '').slice(0, 7)
                        : entity[field.entry]}
                    onChange={(e) => {
                      const updatedEntity = entity
                      updatedEntity[field.entry] = field.type === "checkbox" ? e.target.checked : e.target.value
                      setEntity({...updatedEntity})
                    }}
                />}
            </div>)}
            <button className={'saveButton'} onClick={(e) => onCreate && onCreate(e, entity)}>Sauvegarder</button>
        </form>
    </>
}

function EntityField({field, onChange, value}) {
    const [options, setOptions] = useState([])

    useEffect(() => {
        fetch(`${constants.API_URL}/${field.entry}s?limit=0`)
            .then(response => response.json())
            .then(options => setOptions(options))
            .catch((e) => console.error(e))
    }, [])

    return <select name={field.name} id={field.name} onChange={onChange} value={typeof value === "string" ? value : value?._id}>
        <option value={undefined}></option>
        {options.map(option => <option value={option._id} key={option._id}>{option.name}{option.batchRef && ` (${option.batchRef})`}</option>)}
    </select>
}

function ListField({field, value, onChange}) {
    const [showModal, setShowModal] = useState(false)
    const [items, setItems] = useState([])

    useEffect(() => {
        if (value) {
            loadItems(value)
        }
    }, [])

    async function loadItems(entryValue) {
        fetch(`${constants.API_URL}/${field?.content?.entry}s?id=${entryValue}`)
                .then(response => response.json())
                .then(dbElements => setItems(dbElements))
                .catch((e) => console.error(e))
    }

    function handleItemCreation(createdItem) {
        createdItem.json().then(res => {
            const basketItems = value || []
            basketItems.push(res._id)
            onChange(basketItems)
            setItems([...items, res])
        })
    }

    return <>
        <EntityModal display={showModal} field={field} onChange={handleItemCreation} onClose={() => setShowModal(false)}/>
        <div>
            {items?.map(item => <div className={"listItem"} key={item._id}>
                {field?.content?.entry === "product" && <>
                    <span>{item.name}</span>
                    <span>{item.quantity} kg</span>
                    <span>{item.batchRef}</span>
                    {item.minDurabilityDate && <span>{new Date(item.minDurabilityDate).getMonth()}/{new Date(item.minDurabilityDate).getFullYear()}</span>}
                </>}
            </div>)}
            <button onClick={(e) => {
                e.preventDefault()
                setShowModal(true)
            }}>+</button>
        </div>
    </>
}
