import React from 'react';
import {createGlobalStyle} from "styled-components";
import {constants} from "../utils/constants"

const GlobalStyle = createGlobalStyle`
  .landingPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    > img {
      height: 150px;
    }

    > h1 {
      font-size: 3em;
    }

    .authForm {
      display: flex;
      flex-direction: column;
      gap: 1em;
      width: 100%;
      max-width: 400px;

      > div {
        display: flex;
        flex-direction: column;

        > label {
          margin-bottom: .5rem;
        }

        input{
          padding: .8rem 1rem;
          border: 1px solid var(--grey);
          border-radius: .5rem;
          max-width: 30rem;
          box-sizing: border-box;
        }
      }
    }
  }
`

export default function Landing({user, onAuth}) {
    return <div className={"landingPage"}>
        <GlobalStyle/>
        <img src="logo.png" alt="logo les épices curieuses"/>
        <h1>Les Épices Curieuses ERP</h1>
        {!user && <form className={"authForm"} onSubmit={(e) => {
            e.preventDefault()
            if (document.getElementById("emailField").value === constants.APP_USER
            && document.getElementById("passwordField").value === constants.APP_PASS) {
                onAuth({user: document.getElementById("emailField")})
            }
        }}>
            <h3>Connectez-vous</h3>
            <div>
                <label htmlFor={"emailField"}>Email</label>
                <input type="text" id={"emailField"}/>
            </div>
            <div>
                <label htmlFor={"passwordField"}>Mot de passe</label>
                <input type="password" id={"passwordField"}/>
            </div>
            <button>Se connecter</button>
        </form>}
    </div>
}